<template>
  <div class="lg:mt-5 lg:px-4 max-w-2xl mx-auto side-nav">
    <vue-headful
      title="Template Lead forms | Octoa"
    />

    <modal
      name="promoModal"
      class="promo-modal modal"
      width="90%"
      height="330"
      :max-width="600"
      :adaptive="true"
    >
      <div v-if="!isAcceptedHelp">
        <div class="flex justify-between">
          <div class="title mr-8">
            How do we help to install the lead form?
          </div>
          <div>
            <div
              class="close "
              @click="$modal.hide('promoModal')"
            />
          </div>
        </div>
        <div class="flex mt-3 items-center text-base">
          <div class="mr-3">
            <div class="number">
              1
            </div>
          </div>
          <div>
            As soon as we received your request, we will contact you by email.
          </div>
        </div>
        <div class="flex my-5 items-center text-base">
          <div class="mr-3">
            <div class="number">
              2
            </div>
          </div>
          <div>
            Research and implementation will start once we have all the relevant information.
          </div>
        </div>
        <div class="flex items-center text-base">
          <div class="mr-3">
            <div class="number">
              3
            </div>
          </div>
          <div>
            After installation, all new inquiries will go directly into your Octoa account, and you can manage them straight away!
          </div>
        </div>
        <div class="flex items-center my-6 justify-end">
          <div
            v-if="!isHelpLoading"
            class="green-btn"
            @click="acceptHelp"
          >
            Yes, I want help!
          </div>
          <div v-else>
            <img
              src="@/assets/img/icons/loader.svg"
              width="35"
              class="mx-2 mt-2"
            >
          </div>
        </div>
      </div>
      <div v-else>
        <div class="relative z-10">
          <div
            class="close"
            @click="$modal.hide('promoModal')"
          />
        </div>
        <div class="text-center items-center pt-16">
          <img
            src="@/assets/img/icons/ico-confirmation.svg"
            class="mb-5"
          >
          <h1>We have received your confirmation. We will get back to you very soon!</h1>
        </div>
      </div>
    </modal>

    <v-video-modal
      name="lead"
      title="Lead form creation"
      description="A video to help you get started."
      step="leadform-edit"
      thumbnail="lead-form.png"
      embed="2ItCnf1rz1E"
      :show.sync="isShowTutorialVideoModal"
      @close="closeTutorialVideoModal"
    />

    <v-dialog />
    <div class="flex flex-wrap mb-12">
      <div class="w-full lg:w-1/4">
        <template-menu />
      </div>
      <div class="w-full lg:w-3/4">
        <div
          class="lg:mt-0 bg-white md:h-full mx-4 my-4 px-4 py-8 lg:mx-0 lg:my-0 lg:px-8 lg:py-8 border-grey-lighter border rounded"
          :class="{ 'mt-20' : listofForms && listofForms.length > 0 }"
        >
          <div
            v-if="loading"
            class="text-center"
          >
            <img
              src="@/assets/img/icons/loader.svg"
              width="50"
              class=" py-10"
            >
          </div>

          <div
            v-if="!loading && listofForms && listofForms.length == 0"
            class="text-center"
          >
            <div
              v-if="!isAcceptedHelp"
              class="flex flex-wrap mx-auto max-w-2xl items-center px-2 py-3 my-5 bg-blue-washed-light rounded rounded border-solid text-sm"
            >
              <div class="w-1/12 md:w-10 ml-1 md:ml-0 md:text-center">
                <v-image
                  :src="require('@/assets/img/icons/ico-alert.svg')"
                  :width="21"
                  class="align-bottom"
                />
              </div>
              <div class="w-10/12 md:w-11/12 px-2 text-left text-grey-darker">
                Need help? We <span
                  class="underline cursor-pointer hover:no-underline"
                  @click="getLeadHelp"
                >install your lead form</span> on your website.
              </div>
            </div>
            <div class="flex flex-wrap items-center justify-center">
              <div class="mx-2">
                <v-tutorial-button @click="showTutorialVideoModal" />
              </div>
              <div class="mx-2">
                <router-link
                  to="/templates/lead-forms/create"
                  class="green-btn my-5 inline-block"
                >
                  Create New Lead Form
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="!loading && listofForms && listofForms.length > 0">
            <div
              v-if="!isAcceptedHelp"
              class="flex flex-wrap mx-auto max-w-2xl items-center px-2 py-3 mb-5 bg-blue-washed-light rounded rounded border-solid text-sm">
              <div class="w-1/12 ml-1 md:ml-0 pt-1 md:w-10 md:text-center">
                <v-image
                  :src="require('@/assets/img/icons/ico-alert.svg')"
                  :width="21"
                  class="align-bottom"
                />
              </div>
              <div class="w-10/12 md:w-11/12 px-2 text-left text-grey-darker">
                Need help? We <span
                  class="underline cursor-pointer hover:no-underline"
                  @click="getLeadHelp"
                >install your lead form</span> on your website.
              </div>
            </div>
            <div class="template_list__action flex items-center justify-end">
              <div class="mr-2">
                <v-tutorial-button @click="showTutorialVideoModal" />
              </div>
              <div class="ml-2">
                <router-link
                  to="/templates/lead-forms/create"
                  class="green-btn inline-block"
                >
                  New Lead Form
                </router-link>
              </div>
            </div>

            <v-client-table
              ref="datatable"
              :data="listofForms"
              :columns="columns"
              :options="options"
              @row-click="goDetail"
              @pagination="onPagination"
            >
              <div
                slot="created_at"
                slot-scope="props"
              >
                {{ props.row.created_at | humanDateNoConvert }}
              </div>

              <div
                slot="cta"
                slot-scope="props"
              >
                <img
                  v-tooltip="{ ...tooltip, content: 'Duplicate' }"
                  class="absolute pin-r pin-t z-10 mr-10"
                  src="@/assets/img/icons/ico-duplicate-grey.svg"
                  alt="Delete"
                  width="15"
                  @click="duplicate($event, props.row.id)"
                >
                <img
                  v-tooltip="{ ...tooltip, content: 'Delete' }"
                  class="absolute pin-r pin-t z-10 mr-2"
                  src="@/assets/img/icons/delete.svg"
                  alt="Delete"
                  width="17"
                  @click="deleteItem($event, props.row.id)"
                >
              </div>
            </v-client-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import auth from '@/auth'
import storage from '@/storage'
import { logException } from '@/helpers'

export default {
  data(){
      return {
        page: 1,
        listofForms: null,
        loading: false,
        isHelpLoading: false,
        isAcceptedHelp: false,
        timezone: '',
        user: auth.user(),
        isShowTutorialVideoModal: false,
        tooltip: {
          delay: 0,
          show: false,
          offset: 5,
          trigger: 'hover',
          classes: ['header-tooltip'],
          placement: 'bottom'
        },
        columns: ['name', 'created_at', 'cta'],
        options: {
          filterable: false,
          headings: {
            name: 'Name',
            created_at: 'Date',
            cta: '',
          },
          columnsDisplay: {
            created_at: 'min_tabletL',
            subject: 'min_tabletL',
          },
          orderBy: { column:'name', ascending: true },
          sortable: ['name', 'created_at'],
          descOrderColumns: ['date'],
          perPage: 20,
          perPageValues: [20,50,75,100],
        }
      }
  },
  async mounted(){
    this.isAcceptedHelp = storage.get('closePromoBar')
    this.timezone = this.user.companySettings.timezone
    await this.getList()
  },
  methods: {
    onPagination(index){
      this.page = index
    },
    getLeadHelp(){
      this.$modal.show('promoModal')
    },
    async acceptHelp(){
      this.isHelpLoading = true

      try {
        const { data } = await this.$api.get('promo').leadForm(this.user.company.id, { userId: this.user.me.id})
        this.isAcceptedHelp = true
        this.isPromoBarOpen = false
        storage.set('closePromoBar', true)
        this.isHelpLoading = false
      } catch(e){
        this.$toasted.global.api_error(e)
      }

      this.isHelpLoading = false
    },
    showTutorialVideoModal(){
      this.isShowTutorialVideoModal = true
    },
    closeTutorialVideoModal(){
      this.isShowTutorialVideoModal = false
    },
    async getList(){
      this.loading = true
      try {
        const { data } = await this.$api.get('leadform').list(this.user.company.id)

          this.listofForms = data.forms
          this.loading = false
      } catch(e){
        logException(e)
      }
    },
    goDetail(data){
      this.$router.push(`/templates/lead-forms/${data.row.id}`)
      //window.location = `/templates/lead-forms/${data.row.id}`
    },
    async duplicate(e,id){
      e.stopPropagation()

      const { data } = await this.$api.get('leadform').duplicate(this.user.company.id, id)
      await this.getList()
      this.$refs.datatable.setPage(this.page)
    },
    async deleteItem(e, id){
      e.stopPropagation()

      const canDelete = await this.$alert.confirm({
        title: 'Are you sure you want to delete?',
        text: 'If you delete this the embed form will no longer work in your website.',
      })
      if (canDelete) {
        try {

          const { data } = await this.$api.get('leadform').delete(id)
          this.$toasted.global.general_success({
            message : 'Lead form template deleted.'
          })
          await this.getList()
          this.$refs.datatable.setPage(this.page)

        } catch(e){
          logException(e)
        }
      }
    },
  },
}
</script>
